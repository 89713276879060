import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../layouts/en'
import SubTitle from '../components/Titles/SubTitle'
import SubSubTitle from '../components/Titles/SubSubTitle'

const lang = 'en'

const PublicationTemplate = ({ data, location }) => {
  const [showBibtex, setShowBibtex] = useState(false)
  const { pub } = data
  const image =
    pub.image && pub.image.localFile ? (
      <GatsbyImage
        image={getImage(pub.image.localFile.childImageSharp)}
        className="object-cover w-full h-full"
        alt="Publication image"
      />
    ) : null
  const year = dayjs(pub.publication_date).year()
  const videoId = (url) => {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
    if (match) return match[2]
    return ''
  }

  function handleShowBibtex() {
    setShowBibtex(!showBibtex)
  }

  return (
    <Layout location={location}>
      <Helmet title={pub.title} />
      <SubTitle>{pub.title}</SubTitle>
      <section className="flex flex-row w-full bg-gray-200 sm:flex-col">
        <div className="flex flex-col w-full bg-gray-200 md:flex-row ">
          {image ? (
            <div className="inline-block w-full md:w-[350px] lg:w-[400px] h-full bg-gray-400">
              <GatsbyImage
                image={getImage(pub.image.localFile.childImageSharp)}
                className="min-w-full h-[300px] sm:min-h-full"
                imgStyle={{
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                }}
                alt="Publication image"
              />
            </div>
          ) : null}
          <div className="relative flex flex-col flex-1 p-10">
            <SubSubTitle>Abstract</SubSubTitle>
            {pub.abstract ? (
              <p className="relative text-sm leading-normal font-text">
                {pub.abstract}
              </p>
            ) : (
              <p className="italic text-gray-500 font-text">
                Abstract not available
              </p>
            )}
          </div>
        </div>
      </section>
      <section className="mx-10 my-8 text-sm sm:mx-20 font-text">
        <SubSubTitle>Information</SubSubTitle>
        <div className="grid grid-cols-[auto_1fr] gap-y-4 gap-x-4 mb-2">
          <p className="font-bold text-black uppercase">Authors</p>
          <p>{pub.authors}</p>
          <p className="font-bold text-black uppercase">Publisher</p>
          <p>{pub.publisher}</p>
          <p className="font-bold text-black uppercase">Year of Publication</p>
          <p>{year}</p>
          {pub.bibtex_entry && (
            <>
              <p className="font-bold text-black uppercase">Bibtex</p>
              <button
                className="text-yellow-600 underline max-w-max"
                onClick={handleShowBibtex}
              >
                Open bibtex entry
              </button>
            </>
          )}
          {pub.link && (
            <>
              <p className="font-bold text-black uppercase">Link</p>
              <a
                href={pub.link}
                className="text-yellow-600 underline max-w-max"
                target="_blank"
              >
                Open link to paper
              </a>
            </>
          )}
        </div>

        <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-gray-500 rounded">
          {pub.type}
        </span>

        {pub.thesis === 'bachelor' && (
          <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-red-500 rounded">
            Bachelor Thesis
          </span>
        )}

        {pub.thesis === 'master' && (
          <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-red-500 rounded">
            Master Thesis
          </span>
        )}

        {pub.honorable_mention && (
          <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-yellow-500 rounded">
            Honorable Mention
          </span>
        )}

        {pub.best_paper && (
          <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-yellow-500 rounded">
            Best Paper
          </span>
        )}

        {pub.best_student_paper && (
          <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-yellow-500 rounded">
            Best Student Paper
          </span>
        )}

        {pub.reviewers_choice && (
          <span className="inline-block px-3 py-1 mt-2 mr-2 text-xs font-bold text-white uppercase bg-yellow-500 rounded">
            Reviewers Choice Award
          </span>
        )}
      </section>
      {pub.video_link && videoId(pub.video_link) ? (
        <section className="mx-10 my-8 sm:mx-20">
          <SubSubTitle>Video</SubSubTitle>
          <div className="relative w-full pt-[56.25%]">
            <iframe
              title="Project Video"
              className="absolute top-0 left-0 w-full h-full"
              src={`https://www.youtube-nocookie.com/embed/${videoId(
                pub.video_link
              )}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </section>
      ) : null}
      {pub.members ? (
        <section className="mx-10 my-8 sm:mx-20">
          <SubSubTitle>Contributing Staff</SubSubTitle>
          {pub.members.map((m) => {
            const link = lang === 'de' ? '/de' : ''
            const name = m.name.replace(/\s+/g, '-').toLowerCase()
            return (
              <p className="mb-2">
                <a
                  className="text-yellow-600 underline"
                  href={link + '/team/' + name}
                >
                  {m.name}
                </a>
              </p>
            )
          })}
        </section>
      ) : null}
      {showBibtex ? (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black/90">
          <button
            onClick={handleShowBibtex}
            className="absolute top-5 right-5 w-[30px] h-[30px] flex items-center justify-center cursor-pointer"
          >
            <div className="absolute h-[3px] w-[24px] bg-white -rotate-45"></div>
            <div className="h-[3px] w-[24px] bg-white rotate-45"></div>
          </button>
          <div className="w-full p-10 sm:w-8/12 sm:p-0">
            <h3 className="mb-5 text-xl font-bold text-center text-white font-title">
              Bibtex entry
            </h3>
            <code className="text-xs text-white break-words ">
              {pub.bibtex_entry}
            </code>
          </div>
        </div>
      ) : null}
    </Layout>
  )
}

export default PublicationTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    pub: directusPublication(id: { eq: $id }) {
      authors
      title
      slug
      publisher
      publication_date
      link
      type
      abstract
      bibtex_entry
      thesis
      honorable_mention
      best_paper
      best_student_paper
      reviewers_choice
      video_link
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      members {
        name
      }
      project {
        title
      }
    }
  }
`
